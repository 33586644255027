export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const LOAD_CAREER_CATEGORY_REQUEST = "LOAD_CAREER_CATEGORY_REQUEST";
export const LOAD_CAREER_CATEGORY_SUCCESS = "LOAD_CAREER_CATEGORY_SUCCESS";
export const LOAD_CAREER_CATEGORY_FAIL = "LOAD_CAREER_CATEGORY_FAIL";

export const ALL_CAREER_CATEGORY_REQUEST = "ALL_CAREER_CATEGORY_REQUEST";
export const ALL_CAREER_CATEGORY_SUCCESS = "ALL_CAREER_CATEGORY_SUCCESS";
export const ALL_CAREER_CATEGORY_FAIL = "ALL_CAREER_CATEGORY_FAIL";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";
