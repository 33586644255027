import Page from "@jumbo/shared/Page/Page";
import ConfigureRole from "app/pages/RoleManagement/ConfigureRole";
import ListRole from "app/pages/RoleManagement/ListRole";
import RoleMngtRouteMiddleware from "./middleware/auth/roleMngtValidRoute";

const roleManagementRoutes = [
  {
    middleware: [
      {
        element: RoleMngtRouteMiddleware,
        fallbackPath: "/roles",
      },
    ],
    routes: [
      {
        path: "/roles",
        element: <Page component={ListRole} layout={"vertical-default"} />,
      },

      {
        path: "/addrole",
        element: <Page component={ConfigureRole} layout={"vertical-default"} />,
      },
      {
        path: "/editrole",
        element: <Page component={ConfigureRole} layout={"vertical-default"} />,
      },
    ],
  },
];

export default roleManagementRoutes;
