import Div from "@jumbo/shared/Div/Div";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import styled from "@mui/material/styles/styled";
import DropSingleImage from "app/components/ImageUpload/singleImage";
import { getByIddCourse } from "app/services/apis/addCourse";
import { coursesType } from "app/utils/constants/dropdowns";

import axios from "axios";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
const thumbsContainer = {
  display: "flex",
  marginTop: 0,
  maxHeight: "250px",
};

const thumb = {
  display: "flex",
  borderRadius: 2,
  justifyContent: "center",
  alignContent: "center",
  border: "1px solid #eaeaea",
  // border: "1px solid red",
  marginBottom: 8,
  marginRight: 8,
  width: "70%",
  height: "150px",
  padding: 4,
  boxSizing: "border-box",
};
const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.1),
  borderRadius: "4px",
  display: "inline-block",
  padding: theme.spacing(0.1),
}));

export default function EditCourse() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isSubmitting, setSubmitting] = useState(false);
  const [tags, setTags] = useState("");
  const [tagsData, setTagsData] = useState([]);
  const [files, setFiles] = useState([]);
  const [openView, setOpenView] = useState(false);
  const addTagsItem = (event) => {
    if (tags.trim() === "") {
      return;
    }
    const newItem = tagsData.concat(tags);
    setTagsData(newItem);
    setTags("");
    event.preventDefault();
  };

  console.log(files, "files");
  const handleTagsDelete = (indexToDelete) => {
    console.log("Deleting tag at index:", indexToDelete);
    setTagsData((prevTags) =>
      prevTags.filter((_, index) => index !== indexToDelete)
    );
  };
  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );
  const [initialValues, setInitialValues] = useState({
    title: "",
    type: "",
    price: "",
    description: "",
    banner_image: [],
    tags: [],
  });
  console.log(initialValues, "initialValues");

  const validationSchema = yup.object({
    title: yup.string("Enter Title").required("Title is required"),
    type: yup.string("Enter Type").required("Type is required"),
    price: yup.string("Price").when(["type"], {
      is: (type) => type === "paid",
      then: yup.string().required("Price is required"),
      otherwise: yup.string(),
    }),
    description: yup
      .string("Enter Description")
      .required("Description is required"),
    tags: yup.array("Tags").nullable().required("Tags is required"),
  });

  const getBanquetDetails = async () => {
    try {
      const res = await getByIddCourse(id);
      let data = res?.data?.result;
      setInitialValues({
        title: data.title,
        type: data.type ? data.type : "",
        price: data.price ? data.price : "",
        description: data.description,
        banner_image: data.banner_image || [],
        tags: [],
      });

      setTagsData(data.tags || []);
    } catch (error) {
      console.log("error", error.response.data.message);
    }
  };
  const onClientSave = async (values) => {
    setSubmitting(true);
    const formData = new FormData();
    files.forEach((file) => {
      formData.append(`banner_image`, file);
    });
    formData.append("title", values.title);
    formData.append("description", values.description);
    formData.append("tags", JSON.stringify([...tagsData]));

    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    const data = await axios.post(
      `${process.env.REACT_APP_URL}/course/update-course?id=${id}`,
      formData,
      config
    );
    if (data?.status === 200) {
      Swal.fire({
        icon: "success",
        title: "Course Updated Successfully",
        text: "",
      });
      navigate("/course");
    } else {
      Swal.fire({
        icon: "error",
        title: data?.data?.message,
        text: "",
      });
    }
    // }
    setSubmitting(false);
  };

  useEffect(() => {
    getBanquetDetails();
  }, [openView]);

  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1">Edit Course</Typography>
      <Div>
        <Formik
          key={JSON.stringify(initialValues)}
          validateOnChange={true}
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={onClientSave}
        >
          {({ setFieldValue, values, errors, touched }) => (
            <Form noValidate autoComplete="off">
              {console.log(values, "values")}

              <Div sx={{ mt: 4 }}>
                <Grid container rowSpacing={3} columnSpacing={3}>
                  <Grid item xs={12} sm={6} md={4} lg={4} xl={6}>
                    <FormControl fullWidth>
                      <TextField
                        error={errors.title}
                        helperText={errors.title}
                        label="Course *"
                        name="title"
                        value={values.title}
                        onChange={(e) => setFieldValue("title", e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={5} lg={3} xl={3}>
                    <FormControl fullWidth>
                      <TextField
                        label="Type *"
                        name="type"
                        value={values.type}
                        onChange={(e) => setFieldValue("type", e.target.value)}
                        select
                        fullWidth
                        error={errors.type}
                        helperText={errors.type}
                        InputLabelProps={{
                          shrink: values.type,
                        }}
                        SelectProps={{
                          native: false,
                        }}
                      >
                        <MenuItem value="Select">Select</MenuItem>
                        {coursesType.map((item) => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={5} lg={3} xl={3}>
                    <FormControl fullWidth>
                      <TextField
                        error={errors.price}
                        helperText={errors.price}
                        label="Price *"
                        name="price"
                        disabled={values?.type === "Free"}
                        value={values.price}
                        onChange={(e) => setFieldValue("price", e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4} xl={6}>
                    <FormControl fullWidth>
                      <TextField
                        error={errors.description}
                        helperText={errors.description}
                        label="Description *"
                        name="description"
                        rows={2}
                        value={values.description}
                        onChange={(e) =>
                          setFieldValue("description", e.target.value)
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4} xl={6} mt={1}>
                    <TextField
                      variant="standard"
                      fullWidth
                      size="small"
                      label="Add Tags..."
                      value={tags}
                      onChange={(e) => setTags(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4} xl={1} mt={1}>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={addTagsItem}
                    >
                      Add
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4} xl={5} mt={1}>
                    <div
                      style={{
                        overflowY: "scroll",
                        maxHeight: "80px",
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                    >
                      {tagsData?.map((data, Index) => (
                        <ListItem key={Index}>
                          <Chip
                            variant="outlined"
                            size="small"
                            label={data}
                            onDelete={() => handleTagsDelete(Index)}
                          />
                        </ListItem>
                      ))}
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={4} xl={5} mt={1}>
                    <Typography variant="body1">Banner Images :-</Typography>
                    <DropSingleImage
                      setImage={setFiles}
                      image={files}
                      permission={true}
                    />
                    {files?.length === 0 && (
                      <aside style={thumbsContainer}>
                        <div style={thumb}>
                          <img
                            src={
                              values.banner_image.length
                                ? `${process.env.REACT_APP_BACKEND_IMAGE_PATH}course/${values?.banner_image}`
                                : "https://t4.ftcdn.net/jpg/04/73/25/49/360_F_473254957_bxG9yf4ly7OBO5I0O5KABlN930GwaMQz.jpg"
                            }
                            style={{
                              display: "block",
                              width: "100%",
                              height: "100%",
                            }}
                            alt=""
                          />
                        </div>
                      </aside>
                    )}
                  </Grid>
                </Grid>
                <Div
                  sx={{
                    width: "93.5%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 3,
                    mt: 3,
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      Swal.fire({
                        title: "Are you sure you want to cancel?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Yes",
                        cancelButtonText: "No",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          navigate("/course");
                        }
                      });
                    }}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    sx={{ width: "100px" }}
                    loading={isSubmitting}
                  >
                    Save
                  </LoadingButton>
                </Div>
              </Div>
            </Form>
          )}
        </Formik>
      </Div>
    </Div>
  );
}
