import Page from "@jumbo/shared/Page/Page";
import AddCareerCategory from "app/pages/Master/CareerCategory/AddCareerCategory";
import ListCareerCategory from "app/pages/Master/CareerCategory/ListCareerCategory";
import CareerCategoryRouteMiddleware from "./middleware/auth/careerCategoryValidRoute";
export const masterRoutes = [
  {
    middleware: [
      {
        element: CareerCategoryRouteMiddleware,
        fallbackPath: "/master",
      },
    ],
    routes: [
      {
        path: "/master/career-category",
        element: (
          <Page component={ListCareerCategory} layout={"vertical-default"} />
        ),
      },

      {
        path: "/master/career-category/add",
        element: (
          <Page component={AddCareerCategory} layout={"vertical-default"} />
        ),
      },
      {
        path: "/master/career-category/edit",
        element: (
          <Page component={AddCareerCategory} layout={"vertical-default"} />
        ),
      },
    ],
  },
];
export default masterRoutes;
