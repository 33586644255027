import React from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TableSortLabel,
  Pagination,
} from "@mui/material";
import "./client.css";
import JumboDdMenu from "@jumbo/components/JumboDdMenu/JumboDdMenu";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SettingsIcon from "@mui/icons-material/Settings";
import EditIcon from "@mui/icons-material/Edit";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { displayDateFun } from "app/utils/constants/functions";
import FullScreenLoader from "app/components/ListingPageLoader";

export default function ListClientTable({
  page,
  setPage,
  sort,
  sortBy,
  setSort,
  setSortBy,
}) {
  const navigate = useNavigate();
  const { allClient, TotalPage, loading } = useSelector(
    (state) => state.clientReducer
  );

  const permissions = useSelector(
    (state) => state?.userReducer?.user?.[0]?.role_id?.permissions
  );

  const handleSort = (property) => {
    setSort(sort === "asc" ? "desc" : "asc");
    setSortBy(property);
    setPage(1);
  };

  const handleItemAction = (menuItem) => {
    switch (menuItem.action) {
      case "edit":
        navigate("/edituser", { state: menuItem.data });
        break;
      case "configure":
        navigate("/changepassword", { state: menuItem.data });
        break;
      default:
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <>
      {loading && <FullScreenLoader />}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: "#0B4D74", color: "white" }}>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={sortBy === "first_name"}
                  direction={sort}
                  onClick={() => handleSort("first_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  First Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={sortBy === "last_name"}
                  direction={sort}
                  onClick={() => handleSort("last_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Last Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={sortBy === "gender"}
                  direction={sort}
                  onClick={() => handleSort("gender")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Gender
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  minWidth: "100px",
                  textAlign: "left",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={sortBy === "bob"}
                  direction={sort}
                  onClick={() => handleSort("bob")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  DOB
                </TableSortLabel>
              </TableCell>

              <TableCell
                active={sortBy === "mobile_no"}
                sx={{
                  textAlign: "left",
                  minWidth: "140px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Phone No
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "200px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                <TableSortLabel
                  active={sortBy === "email_id"}
                  direction={sort}
                  onClick={() => handleSort("email_id")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Email ID
                </TableSortLabel>
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "80px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                <TableSortLabel
                  active={sortBy === "status"}
                  direction={sort}
                  onClick={() => handleSort("status")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Status
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                <TableSortLabel
                  active={sortBy === "created_by.first_name"}
                  direction={sort}
                  onClick={() => handleSort("created_by.first_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Created By
                </TableSortLabel>
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Created Date
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Updated Date
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "40px",
                  verticalAlign: "middle",
                  color: "white",
                  position: "sticky",
                  right: 0,
                  height: "58px",
                  zIndex: 1,
                  bgcolor: "#0B4D74",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allClient?.map((row, i) => (
              <TableRow key={i}>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.first_name}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.last_name}
                </TableCell>
                <TableCell sx={{ textAlign: "left", maxWidth: "80px" }}>
                  {row?.gender}
                </TableCell>

                <TableCell sx={{ textAlign: "left" }}>
                  {" "}
                  {displayDateFun(row.dob)}
                </TableCell>

                <TableCell sx={{ textAlign: "left" }}>
                  {row?.country_code} {row?.mobile_no}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.email_id}
                </TableCell>

                <TableCell sx={{ textAlign: "left" }}>
                  {row?.status === false ? "Inactive" : "Active"}
                </TableCell>
                <TableCell sx={{ textTransform: "capitalize" }}>
                  {row?.created_by
                    ? `${row.created_by.first_name} ${row.created_by.last_name}`
                    : "own create"}
                </TableCell>

                <TableCell sx={{ textAlign: "left" }}>
                  {displayDateFun(row.created_at)}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row.updated_at == null
                    ? "N/A"
                    : displayDateFun(row.updated_at)}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    px: 1,
                    position: "sticky",
                    right: 0,
                    zIndex: 1,
                    bgcolor: "white",
                  }}
                >
                  <JumboDdMenu
                    icon={<MoreHorizIcon />}
                    menuItems={
                      permissions.client_edit === true
                        ? [
                            {
                              icon: <EditIcon />,
                              title: "Edit Details",
                              action: "edit",
                              data: row,
                            },
                            {
                              icon: <SettingsIcon />,
                              title: "Change Password",
                              action: "configure",
                              data: row,
                            },
                          ]
                        : [{ title: "No Actions" }]
                    }
                    onClickCallback={handleItemAction}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Pagination
          count={TotalPage || 1}
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
          }}
        />
      </TableContainer>
    </>
  );
}
