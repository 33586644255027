import {
  ALL_JOB_APPLICATIONS_FAIL,
  ALL_JOB_APPLICATIONS_SUCCESS,
  CLEAR_ERRORS,
  LOAD_JOB_APPLICATION_FAIL,
  LOAD_JOB_APPLICATION_REQUEST,
  LOAD_JOB_APPLICATION_SUCCESS,
} from "app/utils/constants/jobApplicationConstants";

const INIT_STATE = {
  loading: false,
  isAuthenticated: false,
  error: null,
  jobApplication: [],
  TotalPage: null,
};

export const jobApplicationReducer = (
  state = { client: INIT_STATE },
  action
) => {
  switch (action.type) {
    case LOAD_JOB_APPLICATION_REQUEST:
      return {
        ...state,
        loading: true,
        isAuthenticated: false,
      };

    case LOAD_JOB_APPLICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        client: action.payload,
      };

    case LOAD_JOB_APPLICATION_FAIL:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        client: null,
        error: action.payload,
      };
    case ALL_JOB_APPLICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        jobApplication: action.payload.data,
        TotalPage: action.payload.totalPage,
      };
    case ALL_JOB_APPLICATIONS_FAIL:
      return {
        ...state,
        jobApplications: [],
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
