import { combineReducers } from "redux";
import { userReducer } from "./userReducer";
import { roleReducer } from "./roleReducer";
import { clientReducer } from "./clientReducer";
import { videoReducer } from "./videoReducer";
import { blogReducer } from "./blogReducer";
import { careerReducer } from "./careerReducer";
import { courseReducer } from "./courseReducer";
import { masterReducer } from "./masterReducer";
import { enquiryReducer } from "./enquiryReducer";
import { jobApplicationReducer } from "./jobApplicationReducer";

const exportReducers = (history) => {
  return combineReducers({
    userReducer: userReducer,
    roleReducer: roleReducer,
    clientReducer: clientReducer,
    videoReducer: videoReducer,
    blogReducer: blogReducer,
    careerReducer: careerReducer,
    courseReducer: courseReducer,
    masterReducer: masterReducer,
    enquiryReducer: enquiryReducer,
    jobApplicationReducer:jobApplicationReducer
  });
};

export default exportReducers;
