export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const LOAD_COURSE_REQUEST = "LOAD_COURSE_REQUEST";
export const LOAD_COURSE_SUCCESS = "LOAD_COURSE_SUCCESS";
export const LOAD_COURSE_FAIL = "LOAD_COURSE_FAIL";

export const ALL_COURSES_REQUEST = "ALL_COURSES_REQUEST";
export const ALL_COURSES_SUCCESS = "ALL_COURSES_SUCCESS";
export const ALL_COURSES_FAIL = "ALL_COURSES_FAIL";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";
