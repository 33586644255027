import Div from "@jumbo/shared/Div/Div";
import AddIcon from "@mui/icons-material/Add";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { addCareer } from "app/services/apis/addCareer";
import { updateCareer } from "app/services/apis/updateCareer";
import { jobTypeList } from "app/utils/constants/dropdowns.js";
import { FieldArray, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AllApis from "app/Apis";
import { Axios } from "index";
export default function AddCareer() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [category, setCategory] = useState([]);
  const [isSubmitting, setSubmitting] = useState(false);
  const { pathname } = useLocation();
  const data = state;

  const client = {
    job_title: data?.job_title ? data?.job_title : "",
    job_type: data?.job_type ? data?.job_type : "Select",
    job_category: data?.job_category ? data?.job_category : "Select",
    location: data?.location ? data?.location : "",
    address: data?.address ? data?.address : "",
    salary: data?.salary ? data?.salary : "",
    responsibilities: state?.responsibilities || [],
    requirement: state?.requirement || [],
  };

  const validationSchema = yup.object({
    job_title: yup.string("Enter Job Title").required("Job Title is required"),
    address: yup.string("Enter Address").required("Address is required"),
    location: yup.string("Enter Location").required("Location is required"),
    salary: yup.string("Enter Salary").required("Salary is required"),
    job_type: yup
      .string()
      .required("Job Type is required")
      .test(
        "Job Type not select",
        "Please select a valid Job Type",
        (value) => value !== "Select"
      ),
    job_category: yup
      .string()
      .required("Job Category is required")
      .test(
        "Job Category not select",
        "Please select a valid Job Category",
        (value) => value !== "Select"
      ),
  });
  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        const categoryResponse = await Axios.get(
          `${AllApis.dropdownList.category}`
        );
        setCategory(categoryResponse.data.result);
      } catch (error) {
        console.error("Error fetching dropdown data", error);
      }
    };

    fetchDropdownData();
  }, []);

  const onClientSave = async (values) => {
    setSubmitting(true);
    if (pathname === "/editcareer") {
      const data = await updateCareer({
        ...values,
        id: state?._id,
      });
      if (data?.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Career Edited Successfully",
          text: "",
        });
        navigate("//career");
      } else {
        Swal.fire({
          icon: "error",
          title: data?.message,
          text: "",
        });
      }
    } else {
      const data = await addCareer(values);
      if (data?.status === 201) {
        Swal.fire({
          icon: "success",
          title: "Career Added Successfully",
          text: "",
        });
        navigate("/career");
      } else {
        Swal.fire({
          icon: "error",
          title: data?.data?.message,
          text: "",
        });
      }
    }
    setSubmitting(false);
  };

  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1">
        {pathname === "/addcareer" ? "Add New Career" : "Edit Career"}
      </Typography>
      <Div>
        <Formik
          validateOnChange={true}
          initialValues={client}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={onClientSave}
        >
          {({ setFieldValue, values, errors, touched }) => (
            <Form noValidate autoComplete="off">
              <Div sx={{ mt: 4 }}>
                <Grid container rowSpacing={3} columnSpacing={3}>
                  <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                    <FormControl fullWidth>
                      <TextField
                        error={errors.job_title}
                        helperText={errors.job_title}
                        label="Job Title *"
                        name="job_title"
                        value={values.job_title}
                        onChange={(e) =>
                          setFieldValue("job_title", e.target.value)
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                    <FormControl fullWidth>
                      <TextField
                        label="Job Category"
                        name="job_category"
                        value={values.job_category}
                        onChange={(e) =>
                          setFieldValue("job_category", e.target.value)
                        }
                        select
                        fullWidth
                        error={errors.job_category}
                        helperText={errors.job_category}
                        InputLabelProps={{
                          shrink: values.job_category,
                        }}
                        SelectProps={{
                          native: false,
                        }}
                      >
                        <MenuItem value="Select">Select</MenuItem>
                        {category.map((item) => (
                          <MenuItem key={item._id} value={item._id}>
                            {item.category}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                    <FormControl fullWidth>
                      <TextField
                        label="Job Type *"
                        onChange={(e) =>
                          setFieldValue("job_type", e.target.value)
                        }
                        select
                        fullWidth
                        error={errors.job_type}
                        helperText={errors.job_type}
                        name="job_type"
                        value={values.job_type}
                        InputLabelProps={{
                          shrink: values.job_type,
                        }}
                        SelectProps={{
                          native: false,
                        }}
                      >
                        <MenuItem value="Select">Select</MenuItem>
                        {jobTypeList.map((item) => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                    <FormControl fullWidth>
                      <TextField
                        error={errors.salary}
                        helperText={errors.salary}
                        label="Salary *"
                        name="salary"
                        value={values.salary}
                        onChange={(e) =>
                          setFieldValue("salary", e.target.value)
                        }
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                    <FormControl fullWidth>
                      <TextField
                        error={errors.location}
                        helperText={errors.location}
                        label="Location *"
                        name="location"
                        value={values.location}
                        onChange={(e) =>
                          setFieldValue("location", e.target.value)
                        }
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                    <FormControl fullWidth>
                      <TextField
                        error={errors.address}
                        helperText={errors.address}
                        label="Address *"
                        name="address"
                        value={values.address}
                        onChange={(e) =>
                          setFieldValue("address", e.target.value)
                        }
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  {/* Requirement Section */}
                  <Grid item xs={12} sm={6}>
                    <Box sx={{ mt: 4 }}>
                      <Typography variant="h4" sx={{ mb: 2 }}>
                        Requirement
                      </Typography>
                      <FieldArray
                        name="requirement"
                        render={(arrayHelpers) => (
                          <div>
                            {values.requirement &&
                            values.requirement.length > 0 ? (
                              values.requirement.map((item, index) => (
                                <Box key={index} sx={{ mb: 2 }}>
                                  <Grid
                                    container
                                    spacing={2}
                                    alignItems="center"
                                  >
                                    <Grid item xs={12} sm={10} md={8}>
                                      <FormControl fullWidth>
                                        <TextField
                                          error={Boolean(
                                            errors.requirement?.[index]
                                          )}
                                          helperText={
                                            errors.requirement?.[index]
                                          }
                                          label="Requirement *"
                                          name={`requirement.${index}`}
                                          value={item}
                                          onChange={(e) =>
                                            setFieldValue(
                                              `requirement.${index}`,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={2} md={2}>
                                      <IconButton
                                        sx={{
                                          color: "red",
                                          "&:hover": {
                                            color: "darkred",
                                          },
                                        }}
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        }
                                      >
                                        <RemoveCircleIcon />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                </Box>
                              ))
                            ) : (
                              <Typography>No Requirement found.</Typography>
                            )}
                            <Button
                              variant="outlined"
                              startIcon={<AddIcon />}
                              onClick={() => arrayHelpers.push("")}
                              sx={{ mt: 1, width: { xs: "100%", sm: "auto" } }}
                              fullWidth
                            >
                              Add Requirement
                            </Button>
                          </div>
                        )}
                      />
                    </Box>
                  </Grid>

                  {/* Responsibilities Section */}
                  <Grid item xs={12} sm={6}>
                    <Box sx={{ mt: 4 }}>
                      <Typography variant="h4" sx={{ mb: 2 }}>
                        Responsibilities
                      </Typography>
                      <FieldArray
                        name="responsibilities"
                        render={(arrayHelpers) => (
                          <div>
                            {values.responsibilities &&
                            values.responsibilities.length > 0 ? (
                              values.responsibilities.map((item, index) => (
                                <Box key={index} sx={{ mb: 2 }}>
                                  <Grid
                                    container
                                    spacing={2}
                                    alignItems="center"
                                  >
                                    <Grid item xs={12} sm={10} md={8}>
                                      <FormControl fullWidth>
                                        <TextField
                                          error={Boolean(
                                            errors.responsibilities?.[index]
                                          )}
                                          helperText={
                                            errors.responsibilities?.[index]
                                          }
                                          label="Responsibilities *"
                                          name={`responsibilities.${index}`}
                                          value={item}
                                          onChange={(e) =>
                                            setFieldValue(
                                              `responsibilities.${index}`,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={2} md={2}>
                                      <IconButton
                                        sx={{
                                          color: "red",
                                          "&:hover": {
                                            color: "darkred",
                                          },
                                        }}
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        }
                                      >
                                        <RemoveCircleIcon />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                </Box>
                              ))
                            ) : (
                              <Typography>
                                No Responsibilities found.
                              </Typography>
                            )}
                            <Button
                              variant="outlined"
                              startIcon={<AddIcon />}
                              onClick={() => arrayHelpers.push("")}
                              sx={{ mt: 1, width: { xs: "100%", sm: "auto" } }}
                              fullWidth
                            >
                              Add Responsibility
                            </Button>
                          </div>
                        )}
                      />
                    </Box>
                  </Grid>
                </Grid>

                <Div
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 2,
                    mt: 3,
                    flexDirection: { xs: "column", sm: "row" },
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      Swal.fire({
                        title: "Are you sure you want to cancel?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Yes",
                        cancelButtonText: "No",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          navigate("/career");
                        }
                      });
                    }}
                    sx={{ width: { xs: "100%", sm: "auto" } }}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    sx={{ width: { xs: "100%", sm: "auto" } }}
                    loading={isSubmitting}
                  >
                    Save
                  </LoadingButton>
                </Div>
              </Div>
            </Form>
          )}
        </Formik>
      </Div>
    </Div>
  );
}
