import {
  ALL_CAREER_CATEGORY_FAIL,
  LOAD_CAREER_CATEGORY_REQUEST,
  ALL_CAREER_CATEGORY_SUCCESS,
  CLEAR_ERRORS,
} from "app/utils/constants/careerCategoryConstants";
import axios from "axios";

export const getAllCareerCategory =
  (search_value, sort, sortBy, page) => async (dispatch) => {
    try {
      dispatch({ type: LOAD_CAREER_CATEGORY_REQUEST });
      const body = {
        filters: {},
        searchFields: {
          string: ["category", "category_description"],
          numbers: ["salary"],
        },
      };
      if (!search_value) {
        search_value = "";
      }
      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const data = await axios.post(
        `${
          process.env.REACT_APP_URL
        }/career-category/list-career-category?${urlParams.toString()}`,
        body,
        config
      );

      dispatch({
        type: ALL_CAREER_CATEGORY_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_CAREER_CATEGORY_FAIL,
        payload: error.response.data.message,
      });
    }
  };

export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
