import Div from "@jumbo/shared/Div/Div";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { addClient } from "app/services/apis/addClient";
import { updateClientDetailsAdmin } from "app/services/apis/updateClientDetailsAdmin";
import { codeList, genderList } from "app/utils/constants/dropdowns.js";
import dayjs from "dayjs";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";

export default function AddClient() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [isSubmitting, setSubmitting] = useState(false);
  const { pathname } = useLocation();
  const data = state;

  const client = {
    first_name: data?.first_name ? data?.first_name : "",
    last_name: data?.last_name ? data?.last_name : "",
    email_id: data?.email_id ? data?.email_id : "",
    gender: data?.gender ? data?.gender : "Select",
    dob: data?.dob || "",
    mobile_no: data?.mobile_no ? data?.mobile_no : "",
    country_code: data?.country_code ? data?.country_code : "+91",
    status:
      data?.status === false || data?.status === true ? data?.status : true,
  };
  const validationSchema = yup.object({
    first_name: yup
      .string("Enter First Name")
      .required("First Name is required")
      .matches(
        /^[A-Za-z\s]+$/,
        "First Name must contain only alphabetic characters"
      ),
    last_name: yup
      .string("Enter Last Name")
      .required("Last Name is required")
      .matches(
        /^[A-Za-z\s]+$/,
        "Last Name must contain only alphabetic characters"
      ),
    dob: yup.string().required("Date Of Birth is required"),
    email_id: yup
      .string("Enter your Email ID")
      .email("Enter a valid Email ID")
      .required("Email is required"),
    gender: yup
      .string()
      .required("Gender is required")
      .test(
        "gender-not-select",
        "Please select a valid Gender",
        (value) => value !== "Select"
      ),

    mobile_no: yup
      .string()
      .matches(/^\d+$/, "Phone number must contain only digits")
      .length(10, "Phone number must be exactly 10 digits")
      .required("Phone number is required"),
  });

  const onClientSave = async (values) => {
    setSubmitting(true);
    if (pathname === "/edituser") {
      const data = await updateClientDetailsAdmin({
        ...values,
        id: state?._id,
      });
      if (data?.status === 200) {
        Swal.fire({
          icon: "success",
          title: "User Edited Successfully",
          text: "",
        });
        navigate("/client");
      } else {
        Swal.fire({
          icon: "error",
          title: data?.message,
          text: "",
        });
      }
    } else {
      const data = await addClient(values);

      if (data?.status === 201) {
        Swal.fire({
          icon: "success",
          title: "User Added Successfully",
          text: "",
        });
        navigate("/user");
      } else {
        Swal.fire({
          icon: "error",
          title: data?.data?.message,
          text: "",
        });
      }
    }
    setSubmitting(false);
  };

  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1">
        {pathname === "/adduser" ? "Add New User" : "Edit User"}
      </Typography>
      <Div>
        <Formik
          validateOnChange={true}
          initialValues={client}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={onClientSave}
        >
          {({ values, setFieldValue, errors, touched }) => (
            <Form noValidate autoComplete="off">
              <Div sx={{ mt: 4 }}>
                <Grid container rowSpacing={3} columnSpacing={3}>
                  <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                    <FormControl fullWidth>
                      <TextField
                        error={errors.first_name}
                        helperText={errors.first_name}
                        label="First Name*"
                        name="first_name"
                        value={values.first_name}
                        onChange={(e) =>
                          setFieldValue("first_name", e.target.value)
                        }
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                    <FormControl fullWidth>
                      <TextField
                        error={errors.last_name}
                        helperText={errors.last_name}
                        label="Last Name*"
                        name="last_name"
                        value={values.last_name}
                        onChange={(e) =>
                          setFieldValue("last_name", e.target.value)
                        }
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                    <FormControl fullWidth>
                      <TextField
                        error={errors.email_id}
                        helperText={errors.email_id}
                        label="Email ID*"
                        name="email_id"
                        value={values.email_id}
                        onChange={(e) =>
                          setFieldValue("email_id", e.target.value)
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                    <FormControl fullWidth>
                      <TextField
                        label="Gender *"
                        name="gender"
                        value={values.gender}
                        onChange={(e) =>
                          setFieldValue("gender", e.target.value)
                        }
                        select
                        fullWidth
                        error={errors.gender}
                        helperText={errors.gender}
                        InputLabelProps={{
                          shrink: values.gender,
                        }}
                        SelectProps={{
                          native: false,
                        }}
                      >
                        <MenuItem value="Select">Select</MenuItem>
                        {genderList.map((item) => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Date Of Birth *"
                        name="dob"
                        value={values.dob ? dayjs(values.dob) : null}
                        onChange={(dob) =>
                          setFieldValue("dob", dob ? dob.toISOString() : "")
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            InputProps={{
                              ...params.InputProps,
                              style: { width: "100%" },
                            }}
                            sx={{
                              width: "100%", // Ensure the TextField takes full width
                              "& .MuiInputBase-root": {
                                width: "100%", // Ensure the inner input element takes full width
                              },
                            }}
                          />
                        )}
                        sx={{ width: "100%" }}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                    <FormControl fullWidth>
                      <Grid container spacing={1}>
                        <Grid item xs={4} sm={4} md={3} lg={3} xl={3}>
                          <FormControl fullWidth>
                            <TextField
                              name="country_code"
                              value={values.country_code}
                              onChange={(e) =>
                                setFieldValue("country_code", e.target.value)
                              }
                              select
                              fullWidth
                              error={errors.country_code}
                              helperText={errors.country_code}
                              InputLabelProps={{
                                shrink: values.country_code,
                              }}
                              SelectProps={{
                                native: false,
                              }}
                            >
                              <MenuItem value="Select">Select</MenuItem>
                              {codeList.map((item) => (
                                <MenuItem key={item} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                            </TextField>
                          </FormControl>
                        </Grid>

                        <Grid item xs={8} sm={8} md={9} lg={9} xl={9}>
                          <TextField
                            error={errors.mobile_no}
                            helperText={errors.mobile_no}
                            label="Mobile No*"
                            name="mobile_no"
                            value={values.mobile_no}
                            onChange={(e) =>
                              setFieldValue("mobile_no", e.target.value)
                            }
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>
                </Grid>
                <Div sx={{ mt: 5 }}>
                  <Typography variant="h5">Status</Typography>
                  <Switch
                    onChange={(e) => {
                      setFieldValue(
                        "status",
                        values?.status === true ? false : true
                      );
                    }}
                    defaultChecked={values?.status === true ? true : false}
                    sx={{
                      p: 0,
                      width: "70px",
                      "& .MuiSwitch-switchBase.Mui-checked": {
                        color: values?.status === true ? "green" : "red",
                        width: "90%",
                      },
                      "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                        {
                          backgroundColor:
                            values.status === true ? "green" : "red",
                        },
                    }}
                  />
                </Div>
                <Div
                  sx={{
                    width: "93.5%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 3,
                    mt: 3,
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      Swal.fire({
                        title: "Are you sure you want to cancel?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Yes",
                        cancelButtonText: "No",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          navigate("/user");
                        }
                      });
                    }}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    sx={{ width: "100px" }}
                    loading={isSubmitting}
                  >
                    Save
                  </LoadingButton>
                </Div>
              </Div>
            </Form>
          )}
        </Formik>
      </Div>
    </Div>
  );
}
