import {
  ALL_CAREER_CATEGORY_FAIL,
  ALL_CAREER_CATEGORY_SUCCESS,
  CLEAR_ERRORS,
  LOAD_CAREER_CATEGORY_FAIL,
  LOAD_CAREER_CATEGORY_REQUEST,
  LOAD_CAREER_CATEGORY_SUCCESS,
} from "app/utils/constants/careerCategoryConstants";

const INIT_STATE = {
  loading: false,
  error: null,
  allCareerCategory: [],
  TotalPage: null,
};

export const masterReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOAD_CAREER_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOAD_CAREER_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        allCareerCategory: action.payload,
      };
    case LOAD_CAREER_CATEGORY_FAIL:
      return {
        ...state,
        loading: false,

        allCareerCategory: null,
        error: action.payload,
      };
    case ALL_CAREER_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,

        allCareerCategory: action.payload.data,
        TotalPage: action.payload.totalPage,
      };
    case ALL_CAREER_CATEGORY_FAIL:
      return {
        ...state,
        allCareerCategory: [],
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
