// import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import Div from "@jumbo/shared/Div";
import { LoadingButton } from "@mui/lab";
import {
  FormControl,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import AllApis from "app/Apis";
import DropMultiImage from "app/components/videoView";
import { addVideo } from "app/services/apis/addVideo";
import { languageList, moduleNoList } from "app/utils/constants/dropdowns";
import { Form, Formik } from "formik";
import { Axios } from "index";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
const AddVideo = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [course, setCourse] = useState([]);
  var initialValues = {
    title: "",
    language: "",
    duration: "",
    description: "",
    module_no: "",
    videos: [],
  };

  const validationSchema = yup.object({
    title: yup.string("Enter Title").required("Title is required"),
    language: yup.string("Enter Language").required("Language is required"),
    duration: yup.string("Enter Duration").required("Duration is required"),
    description: yup
      .string("Enter Description")
      .required("Description is required"),
    module_no: yup.string("Enter Module No").required("Module No is required"),
  });

  const [files, setFiles] = useState([]);

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );
  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await Axios.get(`${AllApis?.dropdownList?.course}`);
        setCourse(response?.data?.result);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };

    fetchCourses();
  }, []);

  const handleEventAdd = async (value) => {
    setLoading(true);

    // const formData = import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
    // const s3 = new S3Client({
    //   region: "eu-north-1",
    //   credentials: {
    //     accessKeyId: "AKIATCKAPDJAZGBB4LFY",
    //     secretAccessKey: "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",
    //   },
    // });

    // async function uploadFile(file) {
    //   const params = {
    //     Bucket: "jetafx-video-storage",
    //     Key: file.name,
    //     Body: file,
    //   };

    //   try {
    //     const data = await s3.send(new PutObjectCommand(params));
    //     const location = `https://jetafx-video-storage.s3.amazonaws.com/${encodeURIComponent(
    //       file.name
    //     )}`;
    //     return location;
    //   } catch (err) {
    //     console.error(err);
    //   }
    // }

    const uploadedLocations = [];
    // for (const file of files) {
    //   const location = await uploadFile(file);
    //   if (location) {
    //     uploadedLocations.push(location);
    //   }
    // }

    const uploadedData = {
      title: value.title,
      language: value.language,
      duration: value.duration,
      description: value.description,
      module_no: value.module_no,
      course: value.course,
      videos: uploadedLocations,
    };
    const data = await addVideo(uploadedData);
    if (data?.status === 201) {
      Swal.fire({
        icon: "success",
        title: "Video Added Successfully",
        text: "",
      });
      navigate("/video");
    } else {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: data?.data?.message,
        text: "",
      });
    }
  };

  return (
    <React.Fragment>
      <Typography variant="h1" mb={3}>
        Add Video
      </Typography>

      <Formik
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(data, { setSubmitting }) => {
          console.log(data, "datass");
          validationSchema
            .validate(data, { abortEarly: false })
            .then(() => {
              handleEventAdd(data);
              setSubmitting(false);
            })
            .catch((validationErrors) => {
              console.error("Validation Errors:", validationErrors);
              setSubmitting(false);
            });
        }}
      >
        {({ setFieldValue, isSubmitting, values, errors, touched }) => (
          <Form noValidate autoComplete="off">
            <Div sx={{ mt: 4 }}>
              <Grid container rowSpacing={3} columnSpacing={3}>
                <Grid item xs={12} sm={12} md={6} lg={5} xl={6}>
                  <FormControl fullWidth>
                    <TextField
                      error={errors.title}
                      helperText={errors.title}
                      label="Title *"
                      name="title"
                      value={values.title}
                      onChange={(e) => setFieldValue("title", e.target.value)}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={5} xl={6}>
                  <FormControl fullWidth>
                    <TextField
                      label="Course *"
                      name="course"
                      value={values.course}
                      onChange={(e) => setFieldValue("course", e.target.value)}
                      select
                      fullWidth
                      error={errors.course}
                      helperText={errors.course}
                      InputLabelProps={{
                        shrink: values.course,
                      }}
                      SelectProps={{
                        native: false,
                      }}
                    >
                      <MenuItem value="Select">Select</MenuItem>
                      {course.map((item) => (
                        <MenuItem key={item._id} value={item?.title}>
                          {item?.title}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                  <FormControl fullWidth>
                    <TextField
                      label="Module No *"
                      name="module_no"
                      value={values.module_no}
                      onChange={(e) =>
                        setFieldValue("module_no", e.target.value)
                      }
                      select
                      fullWidth
                      error={errors.module_no}
                      helperText={errors.module_no}
                      InputLabelProps={{
                        shrink: values.module_no,
                      }}
                      SelectProps={{
                        native: false,
                      }}
                    >
                      <MenuItem value="Select">Select</MenuItem>
                      {moduleNoList.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                  <FormControl fullWidth>
                    <TextField
                      label="Language *"
                      name="language"
                      value={values.language}
                      onChange={(e) =>
                        setFieldValue("language", e.target.value)
                      }
                      select
                      fullWidth
                      error={errors.language}
                      helperText={errors.language}
                      InputLabelProps={{
                        shrink: values.language,
                      }}
                      SelectProps={{
                        native: false,
                      }}
                    >
                      <MenuItem value="Select">Select</MenuItem>
                      {languageList.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                  <FormControl fullWidth>
                    <TextField
                      error={errors.duration}
                      helperText={errors.duration}
                      label="Duration *"
                      name="duration"
                      value={values.duration}
                      onChange={(e) =>
                        setFieldValue("duration", e.target.value)
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                  <FormControl fullWidth>
                    <TextField
                      error={errors.description}
                      helperText={errors.description}
                      label="Description *"
                      name="description"
                      value={values.description}
                      onChange={(e) =>
                        setFieldValue("description", e.target.value)
                      }
                    />
                  </FormControl>
                </Grid>
                {/* </Grid> */}
                {/* <Grid container rowSpacing={3} columnSpacing={3}> */}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="body1">Videos :-</Typography>
                  <DropMultiImage setVideos={setFiles} videos={files} />
                </Grid>
              </Grid>
            </Div>
            <Div
              sx={{
                width: "93.5%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 3,
                mt: 3,
              }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  Swal.fire({
                    title: "Are you sure you want to cancel?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      navigate("/video");
                    }
                  });
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                variant="contained"
                type="submit"
                sx={{ width: "100px" }}
                loading={loading}
              >
                Save
              </LoadingButton>
            </Div>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default AddVideo;
