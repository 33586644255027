
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const LOAD_VIDEOS_REQUEST = "LOAD_VIDEOS_REQUEST";
export const LOAD_VIDEOS_SUCCESS = "LOAD_VIDEOS_SUCCESS";
export const LOAD_VIDEOS_FAIL = "LOAD_VIDEOS_FAIL";

export const ALL_VIDEOS_REQUEST = "ALL_VIDEOS_REQUEST";
export const ALL_VIDEOS_SUCCESS = "ALL_VIDEOS_SUCCESS";
export const ALL_VIDEOS_FAIL = "ALL_VIDEOS_FAIL";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";
