import {
  ALL_BLOGS_FAIL,
  ALL_BLOGS_SUCCESS,
  CLEAR_ERRORS,
  LOAD_BLOG_REQUEST,
} from "app/utils/constants/blogConstants";

import axios from "axios";

export const getAllBlogs =
  (search_value, sort, sortBy, page) => async (dispatch) => {
    try {
      dispatch({ type: LOAD_BLOG_REQUEST });
      const body = {
        filters: {},
        searchFields: {
          string: ["title", "language"],
          numbers: ["created_by"],
        },
      };
      if (!search_value) {
        search_value = "";
      }
      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const data = await axios.post(
        `${process.env.REACT_APP_URL}/blog/list-blog?${urlParams.toString()}`,
        body,
        config
      );

      dispatch({
        type: ALL_BLOGS_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_BLOGS_FAIL,
        payload: error.response.data.message,
      });
    }
  };

export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
