export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const LOAD_BLOG_REQUEST = "LOAD_BLOG_REQUEST";
export const LOAD_BLOG_SUCCESS = "LOAD_BLOG_SUCCESS";
export const LOAD_BLOG_FAIL = "LOAD_BLOG_FAIL";

export const ALL_BLOGS_REQUEST = "ALL_BLOGS_REQUEST";
export const ALL_BLOGS_SUCCESS = "ALL_BLOGS_SUCCESS";
export const ALL_BLOGS_FAIL = "ALL_BLOGS_FAIL";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";
