import CollectionsIcon from "@mui/icons-material/Collections";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LockPersonOutlinedIcon from "@mui/icons-material/LockPersonOutlined";
import PeopleIcon from "@mui/icons-material/People";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ScaleIcon from "@mui/icons-material/Scale";
import SchoolIcon from "@mui/icons-material/School";
import StarBorderPurple500Icon from "@mui/icons-material/StarBorderPurple500";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import WorkIcon from "@mui/icons-material/Work";

const menus = [
  {
    uri: "/dashboard",
    label: "sidebar.menuItem.dashboard",
    type: "nav-item",
    icon: <DashboardIcon sx={{ fontSize: 20 }} />,
  },
  {
    uri: "/members",
    isActiveUri: ["/addmember", "/editmember"],
    label: "sidebar.menuItem.memberManagement",
    type: "nav-item",
    permission: "user_view",
    icon: <PeopleIcon sx={{ fontSize: 20 }} />,
  },

  {
    uri: "/roles",
    isActiveUri: ["/editrole", "/addrole"],
    label: "sidebar.menuItem.roleAndPermission",
    type: "nav-item",
    permission: "role_view",
    icon: <LockPersonOutlinedIcon sx={{ fontSize: 20 }} />,
  },

  {
    label: "sidebar.menu.master",
    type: "collapsible",
    icon: <StarBorderPurple500Icon sx={{ fontSize: 20 }} />,
    children: [
      {
        uri: "/master/career-category",
        label: "sidebar.menuItem.master.career_category",
        isActiveUri: [
          "/master/career-category/add",
          "/master/career-category/edit",
        ],
        permission: "career_category_view",
        type: "nav-item",
        icon: <ScaleIcon sx={{ fontSize: 20 }} />,
      },
    ],
  },

  {
    label: "sidebar.menu.course",
    type: "collapsible",
    icon: <SchoolIcon sx={{ fontSize: 20 }} />,
    children: [
      {
        uri: "/course",
        isActiveUri: ["/editcourse", "/addcourse"],
        label: "sidebar.menuItem.course.Jeta_course",
        type: "nav-item",
        permission: "course_view",
        icon: <StarBorderPurple500Icon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/video",
        isActiveUri: ["/editvideo", "/addvideo"],
        label: "sidebar.menuItem.course.Jeta_video",

        type: "nav-item",
        permission: "video_view",
        icon: <VideoCallIcon sx={{ fontSize: 20 }} />,
      },
    ],
  },

  {
    uri: "/career",
    isActiveUri: ["/editcareer", "/addcareer"],
    label: "sidebar.menuItem.career",
    type: "nav-item",
    permission: "career_view",
    icon: <WorkIcon sx={{ fontSize: 20 }} />,
  },
  {
    uri: "/blog",
    isActiveUri: ["/editblog", "/addblog"],
    label: "sidebar.menuItem.blog",
    type: "nav-item",
    permission: "blog_view",
    icon: <CollectionsIcon sx={{ fontSize: 20 }} />,
  },
  {
    label: "sidebar.menu.clients",
    type: "collapsible",
    icon: <PeopleAltIcon sx={{ fontSize: 20 }} />,
    children: [
      {
        uri: "/user",
        isActiveUri: ["/edituser", "/adduser"],
        label: "sidebar.menuItem.clients.client",
        type: "nav-item",
        permission: "client_view",
        icon: <PeopleAltIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/enquiry",
        isActiveUri: ["/editenquiry", "/addenquiry"],
        label: "sidebar.menuItem.clients.enquiry",
        type: "nav-item",
        permission: "enquiry_view",
        icon: <PeopleAltIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/job-application",
        isActiveUri: ["/editjob-application", "/addjob-application"],
        label: "sidebar.menuItem.clients.jobApplication",
        type: "nav-item",
        permission: "job_application_view",
        icon: <PeopleAltIcon sx={{ fontSize: 20 }} />,
      },
    ],
  },
];

export default menus;
