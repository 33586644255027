import Div from "@jumbo/shared/Div/Div";
import {
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { Form, Formik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";

export default function ChangePassword() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const user = {
    username: state.first_name + " " + state.last_name,
    email_id: state.email_id,
    new_password: "",
    confirm_password: "",
  };
  const validationSchema = yup.object({
    new_password: yup.string().required("New Password is required"),
    confirm_password: yup
      .string()
      .oneOf([yup.ref("new_password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });
  const onPasswordSave = async (values) => {
    //
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    const data = await axios.patch(
      `${process.env.REACT_APP_URL}/user/admin-change-password?id=${state?._id}`,
      {
        new_password: values?.new_password,
      },
      config
    );
    if (data.status === 200) {
      Swal.fire({ icon: "success", title: data.data.message });
      navigate("/user");
    }
  };
  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1">Change User Password</Typography>
      <Formik
        validateOnChange={true}
        initialValues={user}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={onPasswordSave}
      >
        {({ isSubmitting, setFieldValue, errors, values }) => (
          <Form noValidate autoComplete="off">
            <Div
              sx={{
                display: "flex",
                width: "100%",
                flexWrap: "wrap",
                columnGap: 5,
                mt: 4,
              }}
            >
              <Div sx={{ width: "100%", display: "flex", columnGap: 5, mb: 4 }}>
                <Div sx={{ display: "flex", width: "55%" }}>
                  <Typography variant="h5">Username:</Typography>
                  <Typography variant="h5" sx={{ fontWeight: "600", pl: 2 }}>
                    {user?.username}
                  </Typography>
                </Div>
                <Div sx={{ display: "flex", width: "55%" }}>
                  <Typography variant="h5">Email ID:</Typography>
                  <Typography variant="h5" sx={{ fontWeight: "600", pl: 2 }}>
                    {user?.email_id}
                  </Typography>
                </Div>
              </Div>
              <Grid container rowSpacing={3} columnSpacing={3}>
                <Grid item xs={12} sm={12} md={6} lg={5} xl={6}>
                  <FormControl fullWidth>
                    <TextField
                      error={errors.new_password}
                      helperText={errors.new_password}
                      label="New Password *"
                      name="new_password"
                      value={values.new_password}
                      onChange={(e) =>
                        setFieldValue("new_password", e.target.value)
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={5} xl={6}>
                  <FormControl fullWidth>
                    <TextField
                      error={errors.confirm_password}
                      helperText={errors.confirm_password}
                      label="Confirm Password *"
                      name="confirm_password"
                      value={values.confirm_password}
                      onChange={(e) =>
                        setFieldValue("confirm_password", e.target.value)
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Div>
            <Div
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 3,
                mt: 4,
              }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  navigate("/user");
                }}
              >
                Cancel
              </Button>
              <Button variant="contained" type="submit" sx={{ width: "100px" }}>
                Save
              </Button>
            </Div>
          </Form>
        )}
      </Formik>
    </Div>
  );
}
