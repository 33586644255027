import Page from "@jumbo/shared/Page/Page";
import BlogRouteMiddleware from "./middleware/auth/blogValidRoute";
import ListBlog from "app/pages/BlogtManagement/ListBlog";
import AddBlog from "app/pages/BlogtManagement/AddBlog";
import EditBlog from "app/pages/BlogtManagement/EditBlog";

const blogRoutes = [
  {
    middleware: [
      {
        element: BlogRouteMiddleware,
        fallbackPath: "/blog",
      },
    ],
    routes: [
      {
        path: "/blog",
        element: <Page component={ListBlog} layout={"vertical-default"} />,
      },

      {
        path: "/addblog",
        element: <Page component={AddBlog} layout={"vertical-default"} />,
      },
      {
        path: "/editblog/:id",
        element: <Page component={EditBlog} layout={"vertical-default"} />,
      },
    ],
  },
];

export default blogRoutes;
