export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const LOAD_CLIENT_REQUEST = "LOAD_CLIENT_REQUEST";
export const LOAD_CLIENT_SUCCESS = "LOAD_CLIENT_SUCCESS";
export const LOAD_CLIENT_FAIL = "LOAD_CLIENT_FAIL";

export const ALL_CLIENTS_REQUEST = "ALL_CLIENTS_REQUEST";
export const ALL_CLIENTS_SUCCESS = "ALL_CLIENTS_SUCCESS";
export const ALL_CLIENTS_FAIL = "ALL_CLIENTS_FAIL";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";
