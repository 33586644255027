import Page from "@jumbo/shared/Page/Page";
import ClientRouteMiddleware from "./middleware/auth/clientValidRoute";
import ListClient from "app/pages/ClientManagement/Clients/ListClient";
import AddClient from "app/pages/ClientManagement/Clients/AddClient";
import EnquiryRouteMiddleware from "./middleware/auth/enquiryValidRoute";
import JobApplicationRouteMiddleware from "./middleware/auth/jobApplicationValidRoute";
import ListJobApplication from "app/pages/ClientManagement/JobApplication/ListJobApplication";
import ListEnquiry from "app/pages/ClientManagement/Enquiry/ListEnquiry";

const clientManagementRoutes = [
  {
    middleware: [
      {
        element: ClientRouteMiddleware,
        fallbackPath: "/user",
      },
    ],
    routes: [
      {
        path: "/user",
        element: <Page component={ListClient} layout={"vertical-default"} />,
      },

      {
        path: "/adduser",
        element: <Page component={AddClient} layout={"vertical-default"} />,
      },
      {
        path: "/edituser",
        element: <Page component={AddClient} layout={"vertical-default"} />,
      },
    ],
  },
  {
    middleware: [
      {
        element: JobApplicationRouteMiddleware,
        fallbackPath: "/job-application",
      },
    ],
    routes: [
      {
        path: "/job-application",
        element: (
          <Page component={ListJobApplication} layout={"vertical-default"} />
        ),
      },
    ],
  },
  {
    middleware: [
      {
        element: EnquiryRouteMiddleware,
        fallbackPath: "/enquiry",
      },
    ],
    routes: [
      {
        path: "/enquiry",
        element: <Page component={ListEnquiry} layout={"vertical-default"} />,
      },
    ],
  },
];

export default clientManagementRoutes;
