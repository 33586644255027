import Page from "@jumbo/shared/Page/Page";
import CareerRouteMiddleware from "./middleware/auth/careerValidRoute";
import ListCareer from "app/pages/CareerManagement/ListCareer";
import AddCareer from "app/pages/CareerManagement/AddCareer";



const careertRoutes = [
  {
    middleware: [
      {
        element: CareerRouteMiddleware,
        fallbackPath: "/career",
      },
    ],
    routes: [
      {
        path: "/career",
        element: <Page component={ListCareer} layout={"vertical-default"} />,
      },

      {
        path: "/addcareer",
        element: <Page component={AddCareer} layout={"vertical-default"} />,
      },
      {
        path: "/editcareer",
        element: <Page component={AddCareer} layout={"vertical-default"} />,
      },
    ],
  },
  


];

export default careertRoutes;
