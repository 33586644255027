import {
  ALL_ENQUIRYS_FAIL,
  ALL_ENQUIRYS_SUCCESS,
  CLEAR_ERRORS,
  LOAD_ENQUIRY_FAIL,
  LOAD_ENQUIRY_REQUEST,
  LOAD_ENQUIRY_SUCCESS,
} from "app/utils/constants/enquiryConstants";

const INIT_STATE = {
  loading: false,
  error: null,
  enquiry: [],
  TotalPage: null,
};

export const enquiryReducer = (state = { client: INIT_STATE }, action) => {
  switch (action.type) {
    case LOAD_ENQUIRY_REQUEST:
      return {
        ...state,
        loading: true,
        isAuthenticated: false,
      };

    case LOAD_ENQUIRY_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        client: action.payload,
      };

    case LOAD_ENQUIRY_FAIL:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        client: null,
        error: action.payload,
      };
    case ALL_ENQUIRYS_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        enquiry: action.payload.data,
        TotalPage: action.payload.totalPage,
      };
    case ALL_ENQUIRYS_FAIL:
      return {
        ...state,
        enquirys: [],
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
