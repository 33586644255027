import JumboDdMenu from "@jumbo/components/JumboDdMenu/JumboDdMenu";
import EditIcon from "@mui/icons-material/Edit";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { displayDateFun } from "app/utils/constants/functions";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./course.css";
import FullScreenLoader from "app/components/ListingPageLoader";

export default function ListCourseTable({
  page,
  setPage,
  sort,
  sortBy,
  setSort,
  setSortBy,
}) {
  const navigate = useNavigate();
  const { allCourse, TotalPage, loading } = useSelector(
    (state) => state.courseReducer
  );

  const permissions = useSelector(
    (state) => state?.userReducer?.user?.[0]?.role_id?.permissions
  );

  const handleSort = (property) => {
    setSort(sort === "asc" ? "desc" : "asc");
    setSortBy(property);
    setPage(1);
  };

  const handleItemAction = (menuItem) => {
    switch (menuItem.action) {
      case "edit":
        navigate(`/editcourse/${menuItem.data._id}`);
        break;
      default:
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <>
      {loading && <FullScreenLoader />}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: "#0B4D74", color: "white" }}>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={sortBy === "title"}
                  direction={sort}
                  onClick={() => handleSort("title")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Course
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "200px",
                  verticalAlign: "middle",
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                }}
              >
                Image
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white",
                  },
                  minWidth: "150px",
                  verticalAlign: "middle",
                }}
              >
                Description
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  verticalAlign: "middle",
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white",
                  },
                }}
              >
                Tags
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                <TableSortLabel
                  active={sortBy === "created_by.first_name"}
                  direction={sort}
                  onClick={() => handleSort("created_by.first_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Created By
                </TableSortLabel>
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Created Date
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "40px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          {allCourse && allCourse?.length ? (
            <TableBody>
              {allCourse?.map((row, i) => (
                <TableRow key={i}>
                  <TableCell sx={{ textAlign: "left" }}>{row?.title}</TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    <img
                      src={`${process.env.REACT_APP_BACKEND_IMAGE_PATH}/course/${row?.banner_image}`}
                      alt={row?.title}
                      style={{
                        width: "180px",
                        height: "120px",
                        objectFit: "fill",
                      }}
                    />
                  </TableCell>

                  <TableCell sx={{ textAlign: "left" }}>
                    {row?.description}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row?.tags?.map((tag) => tag)?.join(", ")}
                  </TableCell>
                  <TableCell sx={{ textTransform: "capitalize" }}>
                    {row?.created_by?.first_name} {row?.created_by?.last_name}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {displayDateFun(row?.created_at)}
                  </TableCell>

                  <TableCell sx={{ textAlign: "left" }}>
                    <JumboDdMenu
                      icon={<MoreHorizIcon />}
                      menuItems={
                        permissions?.course_edit === true
                          ? [
                              {
                                icon: <EditIcon />,
                                title: "Edit Course Details",
                                action: "edit",
                                data: row,
                              },
                            ]
                          : [{ title: "No Actions" }]
                      }
                      onClickCallback={handleItemAction}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={7} align="center">
                  Data Not Found !!
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
        <Pagination
          count={TotalPage || 1}
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
          }}
        />
      </TableContainer>
    </>
  );
}
