import Page from "@jumbo/shared/Page/Page";

import AddCourse from "app/pages/CourseManagement/AddCourse";
import ListCourse from "app/pages/CourseManagement/ListCourse";
import CourseRouteMiddleware from "./middleware/auth/courseValidRoute";
import EditCourse from "app/pages/CourseManagement/EditCourse";

const courseManagementRoutes = [
  {
    middleware: [
      {
        element: CourseRouteMiddleware,
        fallbackPath: "/course",
      },
    ],
    routes: [
      {
        path: "/course",
        element: <Page component={ListCourse} layout={"vertical-default"} />,
      },

      {
        path: "/addcourse",
        element: <Page component={AddCourse} layout={"vertical-default"} />,
      },
      {
        path: "/editcourse/:id",
        element: <Page component={EditCourse} layout={"vertical-default"} />,
      },
    ],
  },
];

export default courseManagementRoutes;
