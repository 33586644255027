import Div from "@jumbo/shared/Div/Div";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import styled from "@mui/material/styles/styled";
import DropSingleImage from "app/components/ImageUpload/singleImage";
import { addCourse } from "app/services/apis/addCourse";
import { coursesType } from "app/utils/constants/dropdowns";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Swal from "sweetalert2";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.1),
  borderRadius: "4px",
  display: "inline-block",
  padding: theme.spacing(0.1),
}));

export default function AddCourse() {
  const navigate = useNavigate();

  const [isSubmitting, setSubmitting] = useState(false);
  const [tags, setTags] = useState("");
  const [tagsData, setTagsData] = useState([]);
  const [bannerImage, setBannerImage] = useState([]);

  const addTagsItem = (event) => {
    event.preventDefault();

    if (tags.trim() === "") {
      return;
    }

    const lowerCaseTagsData = tagsData.map((tag) => tag.toLowerCase());
    const lowerCaseTag = tags.toLowerCase();

    // Check if the tag already exists
    if (lowerCaseTagsData.includes(lowerCaseTag)) {
      // Prevent the error message from showing multiple times for the same tag
      if (!toast.isActive("duplicate-error")) {
        toast.error("Tag already exists", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastId: "duplicate-error", // Assign a unique toast ID
        });
      }
      return;
    }

    // Add new tag if not duplicate
    const newItem = tagsData.concat(tags);
    setTagsData(newItem);
    setTags("");
  };

  const handleTagsDelete = (indexToDelete) => {
    setTagsData((prevTags) =>
      prevTags.filter((_, index) => index !== indexToDelete)
    );
  };
  useEffect(
    () => () => {
      bannerImage.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [bannerImage]
  );

  const client = {
    title: "",
    type: "",
    price: "",
    description: "",
    banner_image: [],
    tags: [],
  };
  const validationSchema = yup.object({
    title: yup.string("Enter Title").required("Title is required"),
    type: yup.string("Enter Type").required("Type is required"),
    price: yup.string("Price").when(["type"], {
      is: (type) => type === "Paid",
      then: yup.string().required("Price is required"),
      otherwise: yup.string(),
    }),
    banner_image: yup.mixed().required("Banner image is required"),
    description: yup
      .string("Enter Description")
      .required("Description is required"),
  });

  const onCourseSave = async (values) => {
    setSubmitting(true);
    const formData = new FormData();
    bannerImage.forEach((file) => {
      formData.append(`banner_image`, file);
    });

    formData.append("title", values.title);
    formData.append("description", values.description);
    formData.append("tags", JSON.stringify([...tagsData]));

    try {
      const data = await addCourse(formData);
      if (data?.status === 201) {
        Swal.fire({
          icon: "success",
          title: "Course Added Successfully",
          text: "",
        });
        navigate("/course");
      } else {
        Swal.fire({
          icon: "error",
          title: data?.data?.message,
          text: "",
        });
      }
    } catch (error) {
      console.log(error, "error");
    }
    setSubmitting(false);
  };

  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1">Add New Course</Typography>
      <Div>
        <Formik
          validateOnChange={true}
          initialValues={client}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={onCourseSave}
        >
          {({ setFieldValue, values, errors, touched }) => (
            <Form noValidate autoComplete="off">
              {console.log(values, "values")}
              <Div sx={{ mt: 4 }}>
                <Grid container rowSpacing={3} columnSpacing={3}>
                  <Grid item xs={12} sm={6} md={4} lg={4} xl={6}>
                    <FormControl fullWidth>
                      <TextField
                        error={errors.title}
                        helperText={errors.title}
                        label="Course *"
                        name="title"
                        value={values.title}
                        onChange={(e) => setFieldValue("title", e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={5} lg={3} xl={3}>
                    <FormControl fullWidth>
                      <TextField
                        label="Type *"
                        name="type"
                        value={values.type}
                        onChange={(e) => setFieldValue("type", e.target.value)}
                        select
                        fullWidth
                        error={errors.type}
                        helperText={errors.type}
                        InputLabelProps={{
                          shrink: values.type,
                        }}
                        SelectProps={{
                          native: false,
                        }}
                      >
                        <MenuItem value="Select">Select</MenuItem>
                        {coursesType.map((item) => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={5} lg={3} xl={3}>
                    <FormControl fullWidth>
                      <TextField
                        error={errors.price}
                        helperText={errors.price}
                        label="Price *"
                        name="price"
                        disabled={values?.type === "Free"}
                        value={values.price}
                        onChange={(e) => setFieldValue("price", e.target.value)}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={4} xl={6}>
                    <FormControl fullWidth>
                      <TextField
                        error={errors.description}
                        helperText={errors.description}
                        label="Description *"
                        name="description"
                        rows={2}
                        value={values.description}
                        onChange={(e) =>
                          setFieldValue("description", e.target.value)
                        }
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={4} xl={5} mt={1}>
                    <TextField
                      variant="standard"
                      fullWidth
                      size="small"
                      label="Add Tags..."
                      value={tags}
                      onChange={(e) => setTags(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4} xl={1} mt={1}>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={addTagsItem}
                    >
                      Add
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4} xl={6} mt={1}>
                    <div
                      style={{
                        overflowY: "scroll",
                        maxHeight: "80px",
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                    >
                      {tagsData?.map((data, Index) => (
                        <ListItem key={Index}>
                          <Chip
                            variant="outlined"
                            size="small"
                            label={data}
                            onDelete={() => handleTagsDelete(Index)}
                          />
                        </ListItem>
                      ))}
                    </div>
                  </Grid>
                  {/* </Grid>{" "}
                <Grid container rowSpacing={3} columnSpacing={3} mt={5}> */}
                  <Grid item xs={12} sm={6} md={4} lg={4} xl={5} mt={1}>
                    <Typography variant="body1">Banner Image :-</Typography>
                    <DropSingleImage
                      setImage={setBannerImage}
                      image={bannerImage}
                      permission={true}
                    />
                  </Grid>
                </Grid>
                <Div
                  sx={{
                    width: "93.5%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 3,
                    mt: 3,
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      Swal.fire({
                        title: "Are you sure you want to cancel?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Yes",
                        cancelButtonText: "No",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          navigate("/course");
                        }
                      });
                    }}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    sx={{ width: "100px" }}
                    loading={isSubmitting}
                  >
                    Save
                  </LoadingButton>
                </Div>
              </Div>
              <ToastContainer />
            </Form>
          )}
        </Formik>
      </Div>
    </Div>
  );
}
