import JumboDdMenu from "@jumbo/components/JumboDdMenu/JumboDdMenu";
import EditIcon from "@mui/icons-material/Edit";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { displayDateFun } from "app/utils/constants/functions";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./video.css";

export default function ListVideoTable({
  page,
  setPage,
  sort,
  sortBy,
  setSort,
  setSortBy,
}) {
  const navigate = useNavigate();
  const { allvideo, TotalPage } = useSelector((state) => state.videoReducer);

  const permissions = useSelector(
    (state) => state?.userReducer?.user?.[0]?.role_id?.permissions
  );

  const handleSort = (property) => {
    setSort(sort === "asc" ? "desc" : "asc");
    setSortBy(property);
    setPage(1);
  };

  const handleItemAction = (menuItem) => {
    switch (menuItem.action) {
      case "edit":
        navigate(`/editvideo/${menuItem.data._id}`, {
          state: menuItem.data,
        });
        break;
      default:
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: "#0B4D74", color: "white" }}>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={sortBy === "title"}
                  direction={sort}
                  onClick={() => handleSort("title")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Title
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={sortBy === "language"}
                  direction={sort}
                  onClick={() => handleSort("language")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Language
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={sortBy === "duration"}
                  direction={sort}
                  onClick={() => handleSort("duration")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Duration
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={sortBy === "module_no"}
                  direction={sort}
                  onClick={() => handleSort("module_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Module
                </TableSortLabel>
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "80px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                <TableSortLabel
                  active={sortBy === "created_by.first_name"}
                  direction={sort}
                  onClick={() => handleSort("created_by.first_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Created By
                </TableSortLabel>
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "80px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Created Date
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "80px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Updated Date
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "40px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          {allvideo && allvideo.length ? (
            <TableBody>
              {allvideo?.map((row, i) => (
                <TableRow key={i}>
                  <TableCell sx={{ textAlign: "left" }}>{row?.title}</TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row?.language}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", maxWidth: "80px" }}>
                    {row?.duration}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", maxWidth: "80px" }}>
                    {row?.module_no}
                  </TableCell>

                  {/* <TableCell sx={{ textAlign: "left" }}>{row?.description}</TableCell> */}

                  <TableCell sx={{ textTransform: "capitalize" }}>
                    {row?.created_by?.first_name} {row?.created_by?.last_name}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {displayDateFun(row.created_at)}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row.updated_at == null
                      ? "N/A"
                      : displayDateFun(row.updated_at)}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    <JumboDdMenu
                      icon={<MoreHorizIcon />}
                      menuItems={
                        permissions?.video_edit === true
                          ? [
                              {
                                icon: <EditIcon />,
                                title: "Edit Video Details",
                                action: "edit",
                                data: row,
                              },
                            ]
                          : [{ title: "No Actions" }]
                      }
                      onClickCallback={handleItemAction}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={7} align="center">
                  Data Not Found !!
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
        <Pagination
          count={TotalPage || 1}
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
          }}
        />
      </TableContainer>
    </>
  );
}
