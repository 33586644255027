import Page from "@jumbo/shared/Page";
import VideoRouteMiddleware from "./middleware/auth/videoValidRoute";
import ListVideo from "app/pages/VideoManagement/ListVideo";
import EditVideo from "app/pages/VideoManagement/EditVideo";
import AddVideo from "app/pages/VideoManagement/AddVideo";

export const videoRoutes = [
  {
    middleware: [
      {
        element: VideoRouteMiddleware,
        fallbackPath: "/video",
      },
    ],
    routes: [
      {
        path: "/video",
        element: <Page component={ListVideo} layout={"vertical-default"} />,
      },
      {
        path: "/addvideo",
        element: <Page component={AddVideo} layout={"vertical-default"} />,
      },
      {
        path: "/editvideo/:id",
        element: <Page component={EditVideo} layout={"vertical-default"} />,
      },
    ],
  },
];
