import {
  ALL_COURSES_FAIL,
  ALL_COURSES_SUCCESS,
  CLEAR_ERRORS,
  LOAD_COURSE_FAIL,
  LOAD_COURSE_REQUEST,
  LOAD_COURSE_SUCCESS,
  LOGIN_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_FAIL,
  LOGOUT_SUCCESS,
} from "app/utils/constants/courseConstants";

const INIT_STATE = {
  loading: false,
  isAuthenticated: false,
  error: null,
  allCourse: [],
  TotalPage: null,
};

export const courseReducer = (state = { client: INIT_STATE }, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
    case LOAD_COURSE_REQUEST:
      return {
        ...state,
        loading: true,
        isAuthenticated: false,
      };
    case LOGIN_SUCCESS:
    case LOAD_COURSE_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        allCourse: action.payload,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        allCourse: null,
        error: action.payload,
      };
    case LOAD_COURSE_FAIL:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        allCourse: null,
        error: action.payload,
      };
    case ALL_COURSES_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        allCourse: action.payload.data,
        TotalPage: action.payload.totalPage,
      };
    case ALL_COURSES_FAIL:
      return {
        ...state,
        allCourses: [],
        error: action.payload,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        allCourse: null,
        allCourses: [],
      };
    case LOGOUT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
