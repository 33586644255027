import Page from "@jumbo/shared/Page/Page";
import ChangePassword from "app/pages/ChangePassword";
import AddUser from "app/pages/MembersManagement/AddMembers";
import ListUser from "app/pages/MembersManagement/ListMembers";
import UserRouteMiddleware from "./middleware/auth/userValidRoute";

const memberManagementRoutes = [
  {
    middleware: [
      {
        element: UserRouteMiddleware,
        fallbackPath: "/members",
      },
    ],
    routes: [
      {
        path: "/members",
        element: <Page component={ListUser} layout={"vertical-default"} />,
      },

      {
        path: "/addmember",
        element: <Page component={AddUser} layout={"vertical-default"} />,
      },
      {
        path: "/editmember",
        element: <Page component={AddUser} layout={"vertical-default"} />,
      },

      {
        path: "/changepassword",
        element: (
          <Page component={ChangePassword} layout={"vertical-default"} />
        ),
      },
    ],
  },
];

export default memberManagementRoutes;
