import Div from "@jumbo/shared/Div/Div";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import styled from "@mui/material/styles/styled";
import DropMultiImage from "app/components/ImageUpload";
import DropSingleImage from "app/components/ImageUpload/singleImage";
import { addBlog } from "app/services/apis/addBlog";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import ReactQuill, { Quill } from "react-quill";
import QuillEmoji from "quill-emoji";
import "react-quill/dist/quill.snow.css";
import "quill-emoji/dist/quill-emoji.css";
Quill.register("modules/emoji", QuillEmoji);

const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image", "emoji"],
    ["clean"],
  ],

  clipboard: {
    matchVisual: false,
  },
  "emoji-toolbar": true,
  "emoji-textarea": false,
};

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.1),
  borderRadius: "4px",
  display: "inline-block",
  padding: theme.spacing(0.1),
}));

export default function AddBlog() {
  const navigate = useNavigate();

  const [isSubmitting, setSubmitting] = useState(false);
  const [tags, setTags] = useState("");
  const [tagsData, setTagsData] = useState([]);
  const [files, setFiles] = useState([]);
  const [bannerImage, setBannerImage] = useState([]);
  const addTagsItem = (event) => {
    event.preventDefault();

    if (tags.trim() === "") {
      return;
    }

    const lowerCaseTagsData = tagsData.map((tag) => tag.toLowerCase());
    const lowerCaseTag = tags.toLowerCase();

    // Check if the tag already exists
    if (lowerCaseTagsData.includes(lowerCaseTag)) {
      // Prevent the error message from showing multiple times for the same tag
      if (!toast.isActive("duplicate-error")) {
        toast.error("Tag already exists", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastId: "duplicate-error", // Assign a unique toast ID
        });
      }
      return;
    }

    // Add new tag if not duplicate
    const newItem = tagsData.concat(tags);
    setTagsData(newItem);
    setTags("");
  };

  const handleTagsDelete = (indexToDelete) => {
    console.log("Deleting tag at index:", indexToDelete);
    setTagsData((prevTags) =>
      prevTags.filter((_, index) => index !== indexToDelete)
    );
  };
  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );
  useEffect(
    () => () => {
      bannerImage.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [bannerImage]
  );
  const client = {
    title: "",
    contant: "",
    description: "",
    blog_images: [],
    banner_image: [],
    tags: [],
  };
  const validationSchema = yup.object({
    title: yup.string("Enter Title").required("Title is required"),
    description: yup
      .string("Enter Description")
      .required("Description is required"),
  });

  const onClientSave = async (values) => {
    setSubmitting(true);
    const formData = new FormData();
    files.forEach((file) => {
      formData.append(`blog_images`, file);
    });
    bannerImage.forEach((file) => {
      formData.append(`banner_image`, file);
    });
    formData.append("contant", values.contant);
    formData.append("title", values.title);
    formData.append("description", values.description);
    formData.append("tags", JSON.stringify([...tagsData]));

    try {
      const data = await addBlog(formData);
      if (data?.status === 201) {
        Swal.fire({
          icon: "success",
          title: "Blog Added Successfully",
          text: "",
        });
        navigate("/blog");
      } else {
        Swal.fire({
          icon: "error",
          title: data?.data?.message,
          text: "",
        });
      }
    } catch (error) {
      console.log(error, "error");
    }
    setSubmitting(false);
  };

  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1">Add New Blog</Typography>
      <Div>
        <Formik
          validateOnChange={true}
          initialValues={client}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={onClientSave}
        >
          {({ setFieldValue, values, errors, touched }) => (
            <Form noValidate autoComplete="off">
              {console.log(values, "values")}
              <Div sx={{ mt: 4 }}>
                <Grid container rowSpacing={3} columnSpacing={3}>
                  <Grid item xs={12} sm={6} md={4} lg={4} xl={6}>
                    <FormControl fullWidth>
                      <TextField
                        error={errors.title}
                        helperText={errors.title}
                        label="Title *"
                        name="title"
                        value={values.title}
                        onChange={(e) => setFieldValue("title", e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4} xl={6}>
                    <FormControl fullWidth>
                      <TextField
                        error={errors.description}
                        helperText={errors.description}
                        label="Description *"
                        name="description"
                        rows={2}
                        value={values.description}
                        onChange={(e) =>
                          setFieldValue("description", e.target.value)
                        }
                      />
                    </FormControl>
                  </Grid>

                  {/* <Grid container rowSpacing={3} columnSpacing={3}> */}
                  <Grid item xs={12} sm={6} md={4} lg={4} xl={5} mt={1}>
                    <TextField
                      variant="standard"
                      fullWidth
                      size="small"
                      label="Add Tags..."
                      value={tags}
                      onChange={(e) => setTags(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4} xl={1} mt={1}>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={addTagsItem}
                    >
                      Add
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4} xl={6} mt={1}>
                    <div
                      style={{
                        overflowY: "scroll",
                        maxHeight: "80px",
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                    >
                      {tagsData?.map((data, Index) => (
                        <ListItem key={Index}>
                          <Chip
                            variant="outlined"
                            size="small"
                            label={data}
                            onDelete={() => handleTagsDelete(Index)}
                          />
                        </ListItem>
                      ))}
                    </div>
                  </Grid>
                </Grid>{" "}
                <Grid container rowSpacing={3} columnSpacing={3} mt={5}>
                  <Grid item xs={12} sm={12} md={8} lg={6} xl={6} mt={1}>
                    <Typography variant="body1">Banner Image :-</Typography>
                    <DropSingleImage
                      setImage={setBannerImage}
                      image={bannerImage}
                      permission={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={8} lg={6} xl={6} mt={1}>
                    <Typography variant="body1">Blog Images :-</Typography>
                    <DropMultiImage setImages={setFiles} images={files} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={1}>
                    <ReactQuill
                      theme="snow"
                      value={values?.contant}
                      onChange={(content, delta, source, editor) => {
                        setFieldValue("contant", content);
                      }}
                      modules={modules}
                      formats={[
                        "header",
                        "font",
                        "size",
                        "bold",
                        "italic",
                        "underline",
                        "strike",
                        "blockquote",
                        "list",
                        "bullet",
                        "link",
                        "image",
                        "emoji",
                      ]}
                      style={{ height: "200px" }}
                    />
                  </Grid>
                </Grid>
                {/* <Div
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 2,
                    mt: 7,
                    flexDirection: { xs: "column", sm: "row" },
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      Swal.fire({
                        title: "Are you sure you want to cancel?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Yes",
                        cancelButtonText: "No",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          navigate("/client");
                        }
                      });
                    }}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    sx={{ width: "100px" }}
                    loading={isSubmitting}
                  >
                    Save
                  </LoadingButton>
                </Div> */}
                <Div
                  sx={{
                    width: "93.5%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 3,
                    mt: 15,
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      Swal.fire({
                        title: "Are you sure you want to cancel?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Yes",
                        cancelButtonText: "No",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          navigate("/blog");
                        }
                      });
                    }}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    sx={{ width: "100px" }}
                    loading={isSubmitting}
                  >
                    Save
                  </LoadingButton>
                </Div>
              </Div>
              <ToastContainer />
            </Form>
          )}
        </Formik>
      </Div>
    </Div>
  );
}
