import {
  ALL_ROLE_SUCCESS,
  LOAD_ROLE_FAIL,
  // ALL_ROLE_REQUEST,
  LOAD_ROLE_REQUEST,
} from "app/utils/constants/roleConstants";
import axios from "axios";

export const getAllRoles =
  (search_value, sort, sortBy, page) => async (dispatch) => {
    try {
      dispatch({ type: LOAD_ROLE_REQUEST });
      const body = {
        searchFields: {
          string: ["role_name"],
          numbers: ["created_by"],
        },
      };
      if (!search_value) {
        search_value = "";
      }

      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const data = await axios.post(
        `${process.env.REACT_APP_URL}/role/list-role?${urlParams.toString()}`,
        body,
        config
      );
      dispatch({
        type: ALL_ROLE_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: LOAD_ROLE_FAIL,
        payload: error.response.data.message,
      });
    }
  };
